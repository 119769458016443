<template lang="pug">
  .journey-body
    app-input(
      placeholder="Type journey name here"
      :value="journey.internal_name"
      :error="formErrors.hasError('internal_name')"
      :error-messages="formErrors.fields.internal_name"
      @input="$emit('update:internal_name', $event)"
    ).journey-body__name

    journey-item(
      v-for="(journeyDay, index) in journey.journey_days"
      :key="journeyDay.id"
      :journey="journeyDay"
      :index="index"
      :formErrors="formErrors"
      @update:sections="$emit('update:sections', {index, sections: $event})"
      @update:course_start_time="$emit('update:journeyDay', {index, field: 'course_start_time', val: $event})"
      @update:course_end_time="$emit('update:journeyDay', {index, field: 'course_end_time', val: $event})"
      @update:section="$emit('update:section', {index, val: $event})"
      @update:selectedSections="$emit('update:selectedSections', $event)"
      @click:remove="$emit('click:remove', index)"
    )

    .journey-body__add
      app-button(block dashed @click.native="openCourses")
        span.text-primary.unselectable Add course +

    app-dialog(v-model="showing" title="Choose course")
      template(#dialog-body)
        course-days-list(
          :cities="cities"
          :showing="showing"
          :meetingSpaces="meetingSpaces"
          :activeCity="activeCity"
          @update:city="loadMeetingSpaces"
          @click:update="$emit('click:update', $event)"
          @click:course-day="selectCourseDay"
        )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin'

export default {
  mixins: [errorsMixin, showingMixin],

  props: {
    formErrors: {type: Object, required: true},
    journey: {required: true, type: Object},
    activeCity: Number
  },

  computed: {
    ...mapGetters({
      _cities: 'crmCourseCities/items',
      meetingSpaces: 'crmMeetingsSpaces/items'
    }),

    cities() {
      return this._cities ? this._cities : []
    }
  },

  methods: {
    ...mapActions({
      loadCities: 'crmCourseCities/list',
      _loadMeetingSpaces: 'crmMeetingsSpaces/list',
    }),

    openCourses() {
      this.loadData()
      this.updateShowing(true)
    },

    async loadData() {
      try {
        return await this.loadCities({cache: true})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    async loadMeetingSpaces(city) {
      try {
        return await this._loadMeetingSpaces({main_city: city.ID})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    selectCourseDay(val) {
      this.$emit('click:course-day', val)
      this.updateShowing(false)
    }
  },

  components: {
    appInput: () => import('@/components/global/actions/BaseInput.vue'),
    appDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    courseDaysList: () => import('../CourseDaysList.vue'),
    journeyItem: () => import('./JourneyItem.vue')
  }
}
</script>

<style lang="scss">
.journey-body {
  padding: 15px 0;
  min-height: 500px;
  background-color: $layout-bg;

  &__name {
    margin-right: 16px;
    margin-left: 60px;
  }

  &__add {
    margin-top: 16px;
    margin-right: 16px;
    margin-left: 60px;
  }
}
</style>
